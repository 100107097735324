import Link from "gatsby-link";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../components/context/AppContext";
import { getFormattedCart, getUpdatedItems, isUserLoggedIn } from "../../../utils/functions";
import CartItem from "../cart-item";
import CheckoutButton from "../../checkout-btn";
import { v4 } from "uuid";
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_CART from "../../../mutations/update-cart";
import GET_CART from "../../../queries/get-cart";
import CLEAR_CART_MUTATION from "../../../mutations/clear-cart";
import ViewCart from '../../data-layer/view-cart'
import DataRemove from '../../data-layer/remove-from-cart'
import DataSingleRemove from '../../data-layer/remove-single-cart'

const CartItemsContainer = () => {
  const [cart, setCart] = useContext(AppContext);
  const [requestError, setRequestError] = useState(null);

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART', data );

      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));

      // Update cart data in React Context.
      setCart(updatedCart);
      //console.log(cart)
    },
  });

  // Update Cart Mutation.
  const [updateCart, { loading: updateCartProcessing }] = useMutation(
    UPDATE_CART,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        if (error) {
          setRequestError(error.graphQLErrors[0].message);
        }
      },
    }
  );

  // Update Cart Mutation.
  const [clearCart, { loading: clearCartProcessing }] = useMutation(
    CLEAR_CART_MUTATION,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        if (error) {
          setRequestError(error.graphQLErrors[0].message);
        }
      },
    }
  );

  /*
   * Handle remove product click.
   *
   * @param {Object} event event
   * @param {Integer} Product Id.
   *
   * @return {void}
   */
  const handleRemoveProductClick = (event, item, cartKey, products) => {
    event.stopPropagation();
    DataSingleRemove(item)
    if (products.length) {
      // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
      const newQty = 0;
      const updatedItems = getUpdatedItems(products, newQty, cartKey);

      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      });
    }
  };

  // Clear the entire cart.
  const handleClearCart = (event) => {
    event.stopPropagation();

    if (clearCartProcessing) {
      return;
    }

    clearCart({
      variables: {
        input: {
          clientMutationId: v4(),
          all: true,
        },
      },
    });
    DataRemove(cart)
  };

//let coupon = isCoupon()
//console.log(coupon.amount, coupon.discountType, getFormattedDate(coupon.dateExpiry).replace(/-/g,'.'))

let authData = isUserLoggedIn()

  return (
    <div className="content-wrap-cart">
      <div style={{textAlign:'center',padding:'0 10%'}}>
      <h1 className="mt-5 woo-next-cart-heading spec-h">Koszyk</h1>
      <p>To jest strona koszyka, tutaj możesz zmienić ilość produktów i przejrzeć swoje zamówienie.
      <br /><br />
      Czy chcesz dodać więcej elementów? <Link className="text-primary" to='/pl/'>Wróć do sklepu</Link>.<b> Jeśli zamówienie wydaje się w porządku, przejdź do kolejnego kroku</b>.
      <br /><br />
      Na stronie zamówienia możesz ustawić żądaną <b>walutę płatności (EUR lub ZŁ), zobaczyć koszty wysyłki i zastosować kody kuponów</b>.
      </p>
      </div>
      {cart ? (
        <div className="container woo-next-cart-wrapper">
      {/*
        Disabled for development: causes errors related to window element.*/}
        <ViewCart props={cart} />
        <h2 className="spec-h">Wybrane produkty</h2>
        <p>Przejrzyj pozycje w koszyku lub zmień ich ilość przed dokonaniem zamówienia.</p>
          <div className="woo-next-cart-table-row row">
            <div className="woo-next-cart-table col-md-8 mb-md-0 mb-5">
	            {cart.products.length &&
	            cart.products.map((item) => (
		            <CartItem
			            item={item}
			            updateCartProcessing={updateCartProcessing}
			            products={cart.products}
			            handleRemoveProductClick={handleRemoveProductClick}
			            updateCart={updateCart}
		            />
	            ))}
              {/*Clear entire cart*/}
              <div className="clear-cart">
                <button
                  className="btn btn-danger "
                  onClick={(event) => handleClearCart(event)}
                  disabled={clearCartProcessing}
                >
                  <span className="woo-next-cart">Wyczyść koszyk</span>
                  <i className="fa fa-arrow-alt-right" />
                </button>
                {clearCartProcessing ? <p>Usuwam...</p> : ""}

                <Link to="/pl/#archive" style={{marginLeft:'1em'}}>
                  <button className="btn btn-primary woo-next-large-black-btn">
                    <span className="woo-next-cart-checkout-txt">
                     Wróć do sklepu
                    </span>
                    <i className="fas fa-long-arrow-alt-right" />
                  </button>
                </Link>
              </div>
            </div>

            {/* Display Errors if any */}
            {requestError ? (
              <div className="mt-5 row woo-next-cart-total-container">
                {" "}
                {requestError}{" "}
              </div>
            ) : (
              ""
            )}

            {/*Cart Total*/}
            <div className="woo-next-cart-total-container col-md-4">
              <h2 className="spec-h">Łączna suma</h2>
              <small className="text-success">{authData?(authData.b2b === '1'
                ? <span>{'Gratulacje! Otrzymujesz 25% rabatu B2B! Wprowadź kod kuponu'}<b> b2bclient</b>{' podczas realizacji transakcji, aby rabat został naliczony.'}</span>
                : null):("")}</small>

              {/*!isCoupon() ?(null):<p id="is-coupon" className="text-success">
              		Gratulacje! Twój kupon jest ważny!<br />
              		Twoja zniżka w wysokości -{coupon.amount}{coupon.discountType === 'PERCENT'?'%':'zł'} została zastosowana.
              </p>*/}
              <table className="table table-hover card" style={{backgroundColor:'whitesmoke',display:'inline-table'}}>
                <tbody>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Wartość:</td>
                    <td className="woo-next-cart-element-amt">
                      {"string" !== typeof cart.totalProductsPrice
                      ? (Number(cart.subtotal)-Number(cart.discountTax.replace('zł',''))).toFixed(2)+'zł' +' | '+cart.totalProductsPriceEu
                      : (Number(cart.subtotal)-Number(cart.discountTax.replace('zł',''))).toFixed(2)+'zł' +' | '+cart.totalProductsPriceEu}
                    </td>
                  </tr>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Dostawa:</td>
                    <td className="woo-next-cart-element-amt">
                    {
                      ((Number(cart.subtotal) > 200) || (Number(cart.totalProductsPriceEu.replace('€','')) > 100))  ?
                      <sub>Darmowa dostawa dostępna na stronie kasy dla zamówień powyżej 200zł lub 100€.</sub> : cart.shippingTotal + ' | 8.00€'}
                    </td>
                  </tr>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Łącznie:</td>
                    <td className="woo-next-cart-element-amt">
                    {(Number(cart.totalProductsPrice.replace('zł','')).toFixed(2))+'zł | ' +cart.totalEuWithShipping }
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{textAlign:'center'}}>
                    <CheckoutButton color={'white'} />
              </p>

            </div>
          </div>


        </div>
      ) : (
        <div className="container mt-5" style={{height: '72vh',textAlign:'center'}}>
          <h2>Brak produktów w koszyku</h2>
          <p>Dodaj nowe produkty, aby rozpocząć zakupy.</p>
          <Link to="/pl/">
            <button className="btn btn-outline-dark">
              <span className="woo-next-cart-checkout-txt">
                Wróć do sklepu
              </span>
              <i className="fas fa-long-arrow-alt-right" />
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CartItemsContainer;
